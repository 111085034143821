import { graphql } from 'gatsby';
import { HeadingFragment as HeadingFragmentTrype } from 'graphqlTypes';
import React, { FC } from 'react';

import { DesktopHeading } from './DesktopHeading';
import { MobileHeading } from './MobileHeading';

export const HeadingFragment = graphql`
  fragment Heading on ContentfulMedicarePartner {
    ...DesktopHeading
    ...MobileHeading
  }
`;

interface HeadingProps {
  data: HeadingFragmentTrype;
}

export const Heading: FC<HeadingProps> = ({ data }) => (
  <>
    <DesktopHeading data={data} />
    <MobileHeading data={data} />
  </>
);
