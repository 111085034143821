import { ConfirmedStep } from 'component-library/components/form/fields';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Subheading } from 'component-library/components/typography/Subheading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { MedicareWelcomeFragment } from 'graphqlTypes';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  SubmitMedicareLeadForm,
  SubmitMedicareLeadFormStateProps,
} from '../submit-lead-form/SubmitMedicareLeadForm';
import {
  formInvitationText,
  getCopyForWelcomePage,
  getLegalCopyForWelcomePage,
  getWelcomeMessage,
} from './shared';

const StyledLegalBody = styled(Body)`
  font-size: 10px;
`;

const MobileWelcomeWrapper = styled(ShowOn).attrs({
  screens: [Screen.Lg, Screen.Md, Screen.Sm],
})`
  padding: 60px 22px 0;
`;

interface MobileWelcomeProps extends SubmitMedicareLeadFormStateProps {
  data: MedicareWelcomeFragment;
}

export const MobileWelcome: FC<MobileWelcomeProps> = ({
  data,
  data: { agencyName },
  formSubmitted,
  setFormSubmitted,
}) => (
  <MobileWelcomeWrapper>
    {formSubmitted ? (
      <ConfirmedStep
        backgroundColor={colors.greenLight}
        calendarBookingLink={data.calendarBookingLink}
      />
    ) : (
      <>
        <Heading variant={4} marginBottom={24}>
          {getWelcomeMessage(data)}
        </Heading>
        <Body variant={3} marginBottom={24}>
          {getCopyForWelcomePage(data)}
        </Body>
        <Flex alignItems="center" column>
          <Subheading variant={2} marginBottom={16}>
            {formInvitationText}
          </Subheading>
        </Flex>
        <SubmitMedicareLeadForm
          initialValues={{
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentNameOrNpn: '',
          }}
          useNpn={data.useNationalProducerNumberInsteadOfAgentName || false}
          agencyName={agencyName || ''}
          formSubmitted={formSubmitted}
          setFormSubmitted={setFormSubmitted}
        />
        <Flex alignItems="center" column>
          <StyledLegalBody variant={4} marginTop={56}>
            {getLegalCopyForWelcomePage(data)}
          </StyledLegalBody>
        </Flex>
      </>
    )}
  </MobileWelcomeWrapper>
);
