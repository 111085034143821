import {
  Header,
  InnerWrapper,
} from 'component-library/components/navigation/desktop/DesktopNav';
import { DesktopNavigationRetirableLogo } from 'component-library/components/navigation/desktop/DesktopNavigationRetirableLogo';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen } from 'component-library/components/wrappers/ShowOn';
import { graphql } from 'gatsby';
import { MobileHeadingFragment as MobileHeadingFragmentType } from 'graphqlTypes';
import React, { FC } from 'react';

import { StyledPartnerLogo } from './shared';

export const MobileHeadingFragment = graphql`
  fragment MobileHeading on ContentfulMedicarePartner {
    id
    agencyName
    logo {
      gatsbyImageData(height: 50)
    }
  }
`;

interface MobileHeadingProps {
  data: MobileHeadingFragmentType;
}

export const MobileHeading: FC<MobileHeadingProps> = ({ data }) => (
  <Header screens={[Screen.Sm, Screen.Md, Screen.Lg]}>
    <InnerWrapper alignItems="center">
      <DesktopNavigationRetirableLogo />
      <Heading variant={5} marginLeft={20}>
        +
      </Heading>
      <StyledPartnerLogo
        image={data.logo?.gatsbyImageData}
        alt={data.agencyName || ''}
      />
    </InnerWrapper>
  </Header>
);
