import axios from 'axios';
import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import {
  FormikPhoneInput,
  FormikTextInput,
} from 'component-library/components/form';
import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';

import { MedicareLeadFormSchema } from './submitMedicareLeadUtils';

interface MedicareMarketingFormData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  agentNameOrNpn: string;
}

export interface SubmitMedicareLeadFormStateProps {
  formSubmitted: boolean;
  setFormSubmitted: () => void;
}

interface SubmitMedicareLeadFormProps extends SubmitMedicareLeadFormStateProps {
  initialValues: MedicareMarketingFormData;
  agencyName: string;
  useNpn?: boolean;
}

export const SubmitMedicareLeadForm: FC<SubmitMedicareLeadFormProps> = ({
  agencyName,
  useNpn,
  initialValues,
  formSubmitted,
  setFormSubmitted,
}) => {
  const submitForm = async (data: MedicareMarketingFormData) => {
    await axios.request({
      method: 'post',
      url: '/api/createMedicareLead',
      data: {
        ...data,
        source: 'medicare',
        medium: 'landing page',
        campaign: agencyName,
        term: data.agentNameOrNpn,
      },
    });
    setFormSubmitted();
    window.scrollTo(0, 0);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={MedicareLeadFormSchema}
    >
      {() => (
        <Form>
          <Field
            label="First name"
            name="firstName"
            placeholder="First Name"
            component={FormikTextInput}
            disabled={formSubmitted}
          />
          <Field
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
            component={FormikTextInput}
            disabled={formSubmitted}
          />
          <Field
            label="Email"
            name="email"
            inputMode="email"
            placeholder="Email"
            component={FormikTextInput}
            disabled={formSubmitted}
          />
          <Field
            label="Phone"
            name="phone"
            placeholder="Phone"
            component={FormikPhoneInput}
            disabled={formSubmitted}
          />
          <Field
            label={useNpn ? 'Agent NPN' : 'Agent Name'}
            name="agentNameOrNpn"
            placeholder={useNpn ? `National Producer Number`: `${agencyName} agent`}
            component={FormikTextInput}
            disabled={formSubmitted}
          />
          <PrimaryButton
            buttonColor={ButtonColor.Peach}
            disabled={formSubmitted}
            alignment="center"
          >
            Request my consultation
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};
