import { graphql } from 'gatsby';
import { WelcomeFragment as WelcomeFragmentType } from 'graphqlTypes';
import React, { FC, useState } from 'react';

import { DesktopWelcome } from './DesktopWelcome';
import { MobileWelcome } from './MobileWelcome';

export const WelcomeFragment = graphql`
  fragment Welcome on ContentfulMedicarePartner {
    id
    ...MedicareWelcome
    agencyName
  }
`;
export interface WelcomeProps {
  data: WelcomeFragmentType;
}

export const Welcome: FC<WelcomeProps> = ({ data }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  return (
    <>
      <DesktopWelcome
        data={data}
        formSubmitted={formSubmitted}
        setFormSubmitted={() => setFormSubmitted(true)}
      />
      <MobileWelcome
        data={data}
        formSubmitted={formSubmitted}
        setFormSubmitted={() => setFormSubmitted(true)}
      />
    </>
  );
};
