import { Flex } from 'component-library/components/layout/Flex';
import {
  Header,
  InnerWrapper,
} from 'component-library/components/navigation/desktop/DesktopNav';
import { DesktopNavigationRetirableLogo } from 'component-library/components/navigation/desktop/DesktopNavigationRetirableLogo';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen } from 'component-library/components/wrappers/ShowOn';
import { graphql } from 'gatsby';
import { DesktopHeadingFragment as DesktopHeadingFragmentType } from 'graphqlTypes';
import React, { FC } from 'react';
import styled from 'styled-components';

import { CallOrChatPhoneNumber } from './CallOrChatPhoneNumber';
import { StyledPartnerLogo } from './shared';

export const DesktopHeadingFragment = graphql`
  fragment DesktopHeading on ContentfulMedicarePartner {
    id
    agencyName
    logo {
      gatsbyImageData(height: 50)
    }
    ...CallOrChatPhoneNumber
  }
`;

const StyledHeader = styled(Header)`
  z-index: 5;
`;

export interface DesktopHeadingProps {
  data: DesktopHeadingFragmentType;
}

export const DesktopHeading: FC<DesktopHeadingProps> = ({ data }) => (
  <StyledHeader screens={[Screen.Xl, Screen.Xxl]}>
    <InnerWrapper alignItems="center" justifyContent="space-between">
      <Flex>
        <DesktopNavigationRetirableLogo />
        <Heading variant={5} marginLeft={20}>
          +
        </Heading>
        <StyledPartnerLogo
          image={data.logo?.gatsbyImageData}
          alt={data.agencyName || ''}
        />
      </Flex>
      <CallOrChatPhoneNumber data={data} />
    </InnerWrapper>
  </StyledHeader>
);
