import Layout from 'common/layouts/Layout';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { graphql } from 'gatsby';
import { MedicareAgencyTemplateQuery } from 'graphqlTypes';
import {
  BestRetirement,
  CoverBases,
  NavigateUnexpected,
  Options,
  PathForward,
} from 'page-components/advisor';
import { Heading, Welcome } from 'page-components/partnerships/medicare';
import { BottomCta } from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface MedicareAgencyProps {
  data: MedicareAgencyTemplateQuery;
}

const MedicareAgency: FC<MedicareAgencyProps> = ({ data }) => {
  if (!data.contentfulMedicarePartner) return null;

  const {
    contentfulMedicarePartner,
    contentfulMedicarePartner: { agencyName },
  } = data;

  return (
    <Layout>
      <PageWrapper background={colors.greenLight}>
        <Heading data={contentfulMedicarePartner} />
        <Welcome data={contentfulMedicarePartner} />
        <BestRetirement />
        <CoverBases />
        <PathForward bodyTextReplacement="There's a big difference between retiring and retiring comfortably. As you need to make key decisions, your advisor will help you understand what options are best for you and what it means for your income, investments, and lifestyle." />
        <NavigateUnexpected
          bodyTextReplacement={`Changes like market volatility, inflation and personal circumstances are inevitable. As these arise, your advisor is available to help you confidently adjust.  If you have big life changes, we’ll let your ${agencyName} agent know so they can revisit your healthcare options.`}
        />
        <Options />
        <BottomCta
          heading="Health and wealth are key to a comfortable retirement."
          body={`From greater peace of mind to ongoing care, we're ready to lend a hand to ${agencyName} clients!`}
          buttonText="Request Your Free Consultation"
          onClick={() => window.scrollTo(0, 0)}
        />
        <Footer />
      </PageWrapper>
    </Layout>
  );
};

export default MedicareAgency;

export const query = graphql`
  query MedicareAgencyTemplate($agencyName: String!) {
    contentfulMedicarePartner(agencyName: { eq: $agencyName }) {
      id
      agencyName
      ...Heading
      ...Welcome
    }
  }
`;
