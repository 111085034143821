import { Grid } from 'common/layouts/grid/Grid';
import { GridColumn } from 'common/layouts/grid/GridColumn';
import { ConfirmedStep } from 'component-library/components/form/fields';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Subheading } from 'component-library/components/typography/Subheading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import { MedicareWelcomeFragment } from 'graphqlTypes';
import { StyledLine } from 'page-components/advisor/hero/HeroLarge';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  SubmitMedicareLeadForm,
  SubmitMedicareLeadFormStateProps,
} from '../submit-lead-form/SubmitMedicareLeadForm';
import { SubmitLeadFormWrapper } from '../submit-lead-form/submitMedicareLeadUtils';
import {
  formInvitationText,
  getCopyForWelcomePage,
  getLegalCopyForWelcomePage,
  getWelcomeMessage,
} from './shared';

const DesktopWelcomeWrapper = styled(ShowOn).attrs({
  screens: [Screen.Xxl, Screen.Xl],
})`
  padding: 60px 30px 0;
`;

const InternalWrapper = styled.div`
  z-index: 2;
  position: relative;
`;

const BodyWrapper = styled.div`
  background: ${colors.greenLight};
`;

interface DesktopWelcomeProps extends SubmitMedicareLeadFormStateProps {
  data: MedicareWelcomeFragment;
}

export const DesktopWelcome: FC<DesktopWelcomeProps> = ({
  data,
  data: { agencyName },
  formSubmitted,
  setFormSubmitted,
}) => (
  <DesktopWelcomeWrapper>
    <InternalWrapper>
      {formSubmitted ? (
        <ConfirmedStep
          backgroundColor={colors.greenLight}
          calendarBookingLink={data.calendarBookingLink}
        />
      ) : (
        <Grid cols={5} desktopCols={5} tabletCols={1} gap={1} spacing={1}>
          <GridColumn span={3} desktopSpan={3}>
            <Heading variant={3} marginBottom={24}>
              {getWelcomeMessage(data)}
            </Heading>
            <Body variant={1}>{getCopyForWelcomePage(data)}</Body>
            <StaticImage src="./images/medicare-welcome-image.png" alt="hero" />
            <BodyWrapper>
              <Body variant={5} marginTop={56}>
                {getLegalCopyForWelcomePage(data)}
              </Body>
            </BodyWrapper>
          </GridColumn>
          <GridColumn span={2} desktopSpan={2}>
            <SubmitLeadFormWrapper column>
              <Subheading variant={2}>{formInvitationText}</Subheading>
              <SubmitMedicareLeadForm
                initialValues={{
                  firstName: '',
                  lastName: '',
                  phone: '',
                  email: '',
                  agentNameOrNpn: '',
                }}
                useNpn={
                  data.useNationalProducerNumberInsteadOfAgentName || false
                }
                agencyName={agencyName || ''}
                formSubmitted={formSubmitted}
                setFormSubmitted={setFormSubmitted}
              />
            </SubmitLeadFormWrapper>
          </GridColumn>
        </Grid>
      )}
    </InternalWrapper>
    <StyledLine />
  </DesktopWelcomeWrapper>
);
