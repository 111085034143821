import { graphql } from 'gatsby';
import {
  GetCopyForWelcomePageFragment as GetCopyForWelcomePageFragmentType,
  GetLegalCopyForWelcomePageFragment as GetLegalCopyForWelcomePageFragmentType,
  GetWelcomeMessageFragment as GetWelcomeMessageFragmentType,
} from 'graphqlTypes';

export const MedicareWelcomeFragment = graphql`
  fragment MedicareWelcome on ContentfulMedicarePartner {
    id
    agencyName
    useNationalProducerNumberInsteadOfAgentName
    calendarBookingLink
    ...GetWelcomeMessage
    ...GetCopyForWelcomePage
    ...GetLegalCopyForWelcomePage
  }
`;

export const GetWelcomeMessageFragment = graphql`
  fragment GetWelcomeMessage on ContentfulMedicarePartner {
    id
    agencyName
  }
`;

export const getWelcomeMessage = (data: GetWelcomeMessageFragmentType) =>
  `Welcome, ${data.agencyName} Clients!`;

export const GetCopyForWelcomePageFragment = graphql`
  fragment GetCopyForWelcomePage on ContentfulMedicarePartner {
    id
    agencyName
    displayLegalDetails
  }
`;

export const getCopyForWelcomePage = (
  data: GetCopyForWelcomePageFragmentType,
) =>
  `${data.agencyName} has partnered with Retirable to offer its clients a free financial consultation with a licensed fiduciary specializing for those in or near retirement.`;

export const GetLegalCopyForWelcomePageFragment = graphql`
  fragment GetLegalCopyForWelcomePage on ContentfulMedicarePartner {
    id
    legalDetails {
      legalDetails
    }
    displayLegalDetails
  }
`;

export const getLegalCopyForWelcomePage = ({
  legalDetails,
  displayLegalDetails,
}: GetLegalCopyForWelcomePageFragmentType) => {
  if (!displayLegalDetails) return '';

  return legalDetails?.legalDetails;
};

export const formInvitationText =
  'Complete this form to request your free financial consultation';
