import { Heading } from 'component-library/components/typography/Heading';
import { graphql } from 'gatsby';
import { CallOrChatPhoneNumberFragment as CallOrChatPhoneNumberFragmentType } from 'graphqlTypes';
import React, { FC } from 'react';
import styled from 'styled-components';

const StyledHeading = styled(Heading)`
  font-size: 24px;
`;

export const CallOrChatPhoneNumberFragment = graphql`
  fragment CallOrChatPhoneNumber on ContentfulMedicarePartner {
    id
    agencyPhoneNumber
  }
`;

interface CallOrChatPhoneNumberProps {
  data: CallOrChatPhoneNumberFragmentType;
}

export const CallOrChatPhoneNumber: FC<CallOrChatPhoneNumberProps> = ({
  data,
}) => {
  if (!data.agencyPhoneNumber) return null;
  return (
    <StyledHeading variant={5}>
      Or Call To Chat: {data.agencyPhoneNumber}
    </StyledHeading>
  );
};
