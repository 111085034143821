import {
  emailValidation,
  firstNameValidation,
  phoneValidation,
} from 'component-library/components/form/fields';
import { Flex } from 'component-library/components/layout/Flex';
import { colors } from 'component-library/styles/colors';
import styled from 'styled-components';
import * as yup from 'yup';

export const MedicareLeadFormSchema = yup.object().shape({
  firstName: firstNameValidation,
  lastName: yup
    .string()
    .required('Last name is required')
    .typeError('Please enter your last name'),
  phone: phoneValidation,
  email: emailValidation,
});

export const SubmitLeadFormWrapper = styled(Flex)`
  background-color: ${colors.teal};
  border-radius: 32px;
  margin: 40px;
  padding: 40px;
  gap: 40px;
`;
